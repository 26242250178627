<template>
  <MasterDetail
    :hasFilter="false"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :cols="cols"
    :customResource="customResource">
  </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
        MasterDetail,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
           opts: {}
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      cols() {
        return [
          {
            key: 'id',
            name: 'Código'
          },
          {
            key: 'razao_social',
            name: 'Razão Social',
          },
          {
            key: 'cnpj',
            name: 'CNPJ',
          },
        ]
      },
      customResource() {
        const resource = this.apiResource(`/v1/captacao/fornecedores`);
        return {
          ...resource,
          get(...params) {
            return resource.get(params).then((result) => {
              return result;
            })
          }
        }
      }
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     //methods: {},
    // watch: {},
}
</script>

<style>
</style>
